@import "../../../styles/variables";

.round {
    width: 48px;
    height: 48px;
    background-color: #FF563C;
    border: 4px solid $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        fill: $white;
    }
}
