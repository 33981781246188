@import "../../../styles/variables";

$sidesOffset: 8px;
$contentSideOffset: 16px;
$buttonSize: 32px;

.curtain {
    padding: $sidesOffset;
    position: fixed;
    display: none;
    opacity: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.50);
    justify-content: flex-end;
    flex-direction: column;
    z-index: 999;

    &.open {
        display: flex;
        opacity: 1;
        transition: display 0.3s ease-in-out;
    }
}


.content {
    padding: $contentSideOffset;
    position: relative;
    width: 100%;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background: $white;
    transform: translateY(100%);
    box-shadow: 0 11px 15px 0 rgba(0, 0, 0, 0.20), 0 9px 46px 0 rgba(0, 0, 0, 0.12), 0 24px 38px 0 rgba(0, 0, 0, 0.14);

    &.open {
        transform: translateY(0);
        transition: transform 0.3s ease-in-out;
    }

    .inner {
        max-height: calc(100vh - ($buttonSize + $sidesOffset + $contentSideOffset + 33px) * 2);
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}

.close {
    width: $buttonSize;
    height: $buttonSize;
    border-radius: 50%;
    background: $white;
    position: absolute;
    right: 0;
    top: calc(-1 * ($buttonSize + 10px));
    padding: 0;
    border: 0;
    box-shadow: 0 11px 15px 0 rgba(0, 0, 0, 0.20), 0 9px 46px 0 rgba(0, 0, 0, 0.12), 0 24px 38px 0 rgba(0, 0, 0, 0.14);
    display: flex;
    justify-content: center;
    align-items: center;
}


.title {
    color: rgba(0, 0, 0, 0.60);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin: 0 0 12px;
}
