// Colors

$white: #fff;
$black: #000;

$black3: #333333;
$black12: #121212;

$green: #0B8D00;
$green2: #0FC470;
$darkGreen: #219653;
$lightGreen: #E1F3D3;
$lightGreen2: #DAEECA;
$lightGreen3: #8CAA76;
$lightGreen4: #9FBF87;

$purple: #383258;
$purple700: #2B2647;
$purple800: #241F3D;
$purple400: #565171;
$purple200: #9C99AC;

$red: #EB5843;

$orange: #FFA500;

$yellow: #FFDE13;
$yellowDark: #FFC806;

$darkBlue: #00446B;
$blue: #189DEA;
$bluePure: #0F8AD1;
$blueLight: #23AFFF;
$darkBlue2: #0682C9;
$blueSecondary: #3699F5;
$blue600: #3091F4;

$mainBlue: #00639A;

$gray: #B0B0B0;
$grayCB: #CBCBCB;
$gray3: #333;
$grayFA: #FAFAFA;
$grayLight: #E7E6EB;
$grayF2: #F2F2F2;

$blackOpacity38: rgba(0, 0, 0, 0.38);
$blackOpacity87: rgba(0, 0, 0, 0.87);
$blackOpacity60: rgba(0, 0, 0, 0.60);
$blackOpacity04: rgba(0, 0, 0, 0.04);
$blackOpacity88: rgba(0, 0, 0, 0.88);
$blackOpacity12: rgba(0, 0, 0, 0.12);

$whiteOpacity40: rgba(255, 255, 255, 0.40);

// Fonts
//$rubik: 'Rubik', sans-serif;
$calibri: 'Calibri', sans-serif;
$iskra: 'Iskra', sans-serif;

$textFont: $calibri;
$titlesFont: $iskra;

$xxs: 'only screen and (min-width: 374px)';
$xs: 'only screen and (min-width: 480px)';


$primaryColor: $purple;
$mainBg: #F6F5E6;

// Text sizes

$size5XL: 96px;
$size4XL: 60px;
$size3XL: 48px;
$size2XL: 34px;
$sizeXL: 24px;
$sizeL: 20px;
$sizeM: 16px;
$sizeS: 14px;
$sizeXS: 12px;
$sizeXXS: 10px;

$subTitleSize: $sizeM;
$subTitle2Size: $sizeS;
$textsSize: $sizeM;
$textsSizeSmall: $sizeS;
$captionSize: $sizeXS;
$overlineSize: $sizeXXS;

$maxContentWidth: 1024px;

